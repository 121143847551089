
































































































































































































































import {Component, Vue} from "vue-property-decorator";
import TableSelectableItem from "../Atoms/TableSelectableItem.vue";
import {ResponseLog} from "../../models/interfaces/ResponseLog";
import {ResponseLogModel} from "../../models/ResponseLogModel";
import {MasterInfoStore, MasterInfoStoreModule} from "../../stores/MasterInfoStore";
import {SheetStore, SheetStoreModule} from "../../stores/SheetStore";

/**
 * @summary 対応ログページを表示するためのビューを提供します.
 */
@Component({
    components: {
        TableSelectableItem
    }
})
export default class AddResponseLogDialog extends Vue {
    private resolve?: (value: ResponseLogModel | undefined | PromiseLike<ResponseLogModel | undefined>) => void = undefined;
    private responseLog = new ResponseLogModel({});
    private isOpend = false;
    private prevId = 0;
    private isNextStep = false;

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    /**
     * @summary 対応ログを追加するためのダイアログを開きます.
     * @param prevId 前ID
     * @returns 追加する内容
     */
    public showAsync(prevId = 0): Promise<ResponseLogModel | undefined> {
        this.isOpend = true;
        this.prevId = prevId;
        if (this.prevId > 0) {
            this.isNextStep = true;
        }
        return new Promise<ResponseLogModel | undefined>(resolve => {
            this.resolve = resolve;
        });
    }

    /**
     * @summary キャンセルしてダイアログを閉じます.
     */
    private cancel(): void {
        this.isOpend = false;
        this.resolve!(undefined);
    }

    /**
     * @summary ダイアログを閉じて追加する対応ログを取得します.
     */
    private ok() {
        if (this.prevId > 0) {
            this.responseLog.nextResponseLogId = this.prevId;
        }

        this.isOpend = false;
        if (this.sheetStore.currentSheetId) {
            this.responseLog.sheetId = this.sheetStore.currentSheetId;
        }
        this.resolve!(this.responseLog);
    }

    private toArray<TValue>(dictionary: {[key: number]: TValue}): TValue[] {
        const array = [];
        for (const key in dictionary) {
            array.push(dictionary[key]);
        }
        return array;
    }
}
