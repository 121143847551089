















































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import * as CONFIG from "@/Config";
import DeleteDialog from "../Atoms/DeleteDialog.vue";
import TableSelectableItem from "../Atoms/TableSelectableItem.vue";
import {ResponseLog} from "../../models/interfaces/ResponseLog";
import {ResponseLogModel} from "../../models/ResponseLogModel";
import {ResponseLogSearchParamModel} from "../../models/ResponseLogSearchParamModel";
import {ResponseLogStoreModule, ResponseLogStore} from "../../stores/ResponseLogStore";
import AddResponseLogDialog from "../Organisms/AddResponseLogDialog.vue";
import {MasterInfoStore, MasterInfoStoreModule} from "../../stores/MasterInfoStore";
import TableTextFieldDialog from "@/components/Atoms/TableTextFieldDialog.vue";
import TableTextareaDialog from "@/components/Atoms/TableTextareaDialog.vue";
import NameById from "@/components/Atoms/NameById.vue";
import {SheetStore, SheetStoreModule} from "@/stores/SheetStore";
import {IdAndName} from "@/models/interfaces/IdAndName";
import ResponseLogThreadView from "@/components/Templates/ResponseLogThreadView.vue";
import ConfirmDialog from "@/components/Atoms/ConfirmDialog.vue";
import ResponseLogSearchForm from "@/components/Organisms/ResponseLogSearchForm.vue";
import TableDatePickerDialog from "../Atoms/TableDatePickerDialog.vue";
import {VDataTableOptions} from "../../models/interfaces/VDataTableOptions";

/**
 * @summary エラー報告ページを表示するためのビューを提供します.
 */
@Component({
    components: {
        DeleteDialog,
        TableSelectableItem,
        AddResponseLogDialog,
        TableTextFieldDialog,
        TableTextareaDialog,
        TableDatePickerDialog,
        NameById,
        ResponseLogThreadView,
        ConfirmDialog,
        ResponseLogSearchForm
    }
})
export default class ResponseLogPage extends Vue {
    private isLoading = false;
    private tableHeight = 0;
    private sortBy: string[] = [];
    private sortDesc: boolean[] = [];
    private updateResponseLog: ResponseLogModel = new ResponseLogModel({});
    private isShowSearchPanel = false;
    private openedThreadId = 0;
    private searchParams: ResponseLogSearchParamModel = CONFIG.DefaultResponseLogSearchParam();
    private headers = [
        {value: "action", width: 26, sortable: false, text: ""},
        {value: "responseLogId", width: 60, align: "center", text: "No"},
        {value: "responseDate", width: 100, text: "対応日"},
        {value: "responseTimeRange", width: 100, text: "対応日時"},
        {value: "systemId", width: 120, align: "center", text: "システム"},
        {value: "responseLogStatusId", width: 110, text: "ステータス"},
        {value: "responseType", width: 100, text: "対応種別"},
        {value: "responsePersonId", width: 70, text: "対応者"},
        {value: "reporter", width: 140, text: "報告元"},
        {value: "target", width: 100, text: "対象"},
        {value: "reportPerson", width: 100, text: "問い合わせ者"},
        {value: "detailContent", width: 340, text: "問い合わせ内容"},
        {value: "responseContent", width: 340, text: "対応内容"},
        {value: "description", width: 340, text: "備考"},
        {value: "troubleCause", width: 340, text: "原因(障害時)"},
        {value: "troubleMeasures", width: 340, text: "対応策(障害時)"},
        {value: "troubleCustomerReport", width: 340, text: "お客様への報告(障害時)"},
        {value: "troubleResponsePersonId", width: 120, text: "対応者(障害時)"},
        {value: "troubleDescription", width: 340, text: "備考(障害時)"},
        {value: "troubleSurveyMinutes", width: 120, text: "調査時間(障害時)"},
        {value: "troubleImpactMinutes", width: 120, text: "稼働影響(障害時)"},
        {value: "troubleLevel", width: 120, text: "障害レベル(障害時)"},
        {value: "action2", width: 60, sortable: false, text: ""}
    ];

    // #region public properties
    /**
     * @summary 対応ログストア
     */
    private get responseLogStore(): ResponseLogStore {
        return ResponseLogStoreModule;
    }

    /**
     * @summary シートストア
     */
    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    /**
     * @summary マスタ情報ストア
     */
    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }
    // #endregin

    private async created(): Promise<void> {
        const json = localStorage.getItem("sortBy_responseLog");
        if (json) {
            this.sortBy = JSON.parse(json);
        }

        const sortDescJson = localStorage.getItem("sortDesc_responseLog");
        if (sortDescJson) {
            this.sortDesc = JSON.parse(sortDescJson);
        }

        window.addEventListener("resize", (e) => {
            this.tableHeight = window.innerHeight - 128;
        });
        this.tableHeight = window.innerHeight - 128;

        this.isLoading = true;
        this.loadSearchParam();
        await this.responseLogStore.fetchResponseLogs(this.searchParams);
        this.isLoading = false;
    }

    private loadSearchParam(): void {
        const json = localStorage.getItem("responseLog_searchParam");
        if (json) {
            Object.assign(this.searchParams, JSON.parse(json));
        }
        else {
            this.searchParams = CONFIG.DefaultResponseLogSearchParam();
        }

        this.searchParams.sheetId = this.sheetStore.currentSheetId;
    }

    private saveSearchParam(): void {
        localStorage.setItem("responseLog_searchParam", JSON.stringify(this.searchParams));
    }

    private onUpdateOptions(options: Partial<VDataTableOptions>): void {
        this.sortBy = [];
        if (options.sortBy) {
            this.sortBy = options.sortBy;
            localStorage.setItem("sortBy_responseLog", JSON.stringify(options.sortBy));
        }

        this.sortDesc = [];
        if (options.sortDesc) {
            this.sortDesc = options.sortDesc;
            localStorage.setItem("sortDesc_responseLog", JSON.stringify(options.sortDesc));
        }
    }

    private async submitSearch(): Promise<void> {
        this.isLoading = true;
        this.searchParams.sheetId = this.sheetStore.currentSheetId;
        await this.responseLogStore.fetchResponseLogs(this.searchParams);
        this.saveSearchParam();
        this.isLoading = false;
    }

    private resetSearchDialog(): void {
        this.searchParams = CONFIG.DefaultResponseLogSearchParam();
    }

    /**
     * @summary エラー報告を保存します
     * @param responseLog 保存するエラー報告
     */
    private async saveResponseLog(responseLog: ResponseLog): Promise<void> {
        this.isLoading = true;
        await this.responseLogStore.saveResponseLog(responseLog);
        this.isLoading = false;
    }

    /**
     * エラー報告を削除します.
     * @param responseLog 削除するエラー報告
     */
    private async deleteResponseLog(responseLog: ResponseLog): Promise<void> {
        const deleteDialog = this.$refs.deleteDialog as DeleteDialog;
        if (deleteDialog && !await deleteDialog.showAsync(`No.${responseLog.responseLogId}`, "を削除しますか？")) {
            return;
        }

        this.isLoading = true;
        if (await this.responseLogStore.deleteResponseLog(responseLog)) {
            this.searchParams.sheetId = this.sheetStore.currentSheetId;
            await this.responseLogStore.fetchResponseLogs(this.searchParams);
        }
        this.isLoading = false;
    }

    /**
     * @summary エラー報告を追加します.
     */
    private async addResponseLog(prevResponseLogId = 0): Promise<undefined | ResponseLog> {
        const dialog = this.$refs.addResponseLogDialog as AddResponseLogDialog;
        if (!dialog) {
            return;
        }

        const responseLog = await dialog.showAsync(prevResponseLogId);
        if (!responseLog) {
            return;
        }

        this.isLoading = true;
        const newResponseLog = await this.responseLogStore.addResponseLog(responseLog);
        if (newResponseLog) {
            this.responseLogStore.responseLogs.push(newResponseLog);
        }
        this.isLoading = false;

        // eslint-disable-next-line no-unneeded-ternary
        return newResponseLog ? newResponseLog : undefined;
    }

    private async openDatePickerDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.datePickerDialog as TableDatePickerDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }
        return result;
    }

    private async openTextFieldDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.textFieldDialog as TableTextFieldDialog;
        if (!dialog) {
            throw new Error("can not find textFieldDialog");
        }

        const target = event.target as HTMLElement;
        if (!target) {
            throw new Error("Event.target is undefined");
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }

        return result;
    }

    private async openTextareaDialog(event: MouseEvent, content: string, height?: number): Promise<string> {
        const dialog = this.$refs.textAreaDialog as TableTextareaDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top, height);
        if (!result) {
            throw new Error("input cancel");
        }

        return result;
    }

    private async openSelectableItem(event: MouseEvent, items: {[key: number]: IdAndName}, values?: {id: number, name: string}[]): Promise<number | undefined> {
        const dialog = this.$refs.selectableItem as TableSelectableItem;
        if (!dialog) {
            return undefined;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return undefined;
        }

        if (!items) {
            items = [{id: 0, name: ""}];
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(items, rect.left, rect.top, values);
        if (!result) {
            throw new Error("input cancel");
        }

        return result;
    }

    private async openStatusSelect(event: MouseEvent, selectItems: {[key: number]: IdAndName}, baseResponseLog: ResponseLogModel): Promise<void> {
        const selectResult = await this.openSelectableItem(event, selectItems).catch(err => void (err));
        if (selectResult) {
            if (selectResult && baseResponseLog.responseLogStatusId === selectResult) {
                return;
            }

            // すでに次対応ログがある場合はそのまま登録していい
            if (baseResponseLog.nextResponseLogId > 0) {
                baseResponseLog.responseLogStatusId = selectResult;
                this.saveResponseLog(baseResponseLog);
                return;
            }

            const requireNextStepIdList: Number[] = [1, 2, 3];
            if (selectResult && requireNextStepIdList.indexOf(selectResult) >= 0) {
                const dialog = this.$refs.confirmDialog as ConfirmDialog;
                if (!dialog) {
                    return;
                }

                const confirmResult = await dialog.showAsync(
                    "ステータス変更確認",
                    "ステータスを変更するには次のステップを登録する必要があります。\n登録ダイアログを開きますか？",
                    "はい、次のステップを登録してステータスを変更します",
                    "いいえ、ステータスの変更をキャンセルします"
                );
                if (confirmResult) {
                    const newResponseLog = await this.addResponseLog(baseResponseLog.responseLogId);

                    // 成功したならステータスIDと次対応ログIDの保存
                    if (newResponseLog) {
                        baseResponseLog.responseLogStatusId = selectResult;
                        baseResponseLog.nextResponseLogId = newResponseLog.responseLogId;
                        this.saveResponseLog(baseResponseLog);
                    }
                }
            }
            else {
                baseResponseLog.responseLogStatusId = selectResult;
                this.saveResponseLog(baseResponseLog);
            }
        }
    }

    private async showThread(responseLog: ResponseLogModel): Promise<void> {
        const responseLogThreadView = this.$refs.responseLogThreadView as ResponseLogThreadView;
        if (responseLogThreadView && responseLog) {
            // 選択状態にする
            this.select(responseLog.responseLogId);

            if (this.openedThreadId === responseLog.responseLogId) {
                this.openedThreadId = 0;
                responseLogThreadView.close();
            }
            else {
                this.openedThreadId = responseLog.responseLogId;
                await responseLogThreadView.showAsync(responseLog);
            }
        }
    }

    private systemColClass(): string {
        return "system-col clickable-div";
    }

    private select(responseLogId: number): void {
        if (this.responseLogStore.selectedIds.indexOf(responseLogId) >= 0) {
            this.responseLogStore.unselectBy(responseLogId);
        }
        else {
            this.responseLogStore.selectBy(responseLogId);
        }
    }
}
