import axios from "axios";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Task } from "../models/interfaces/Task";
import store from "../store";
import { ResponseLog } from "@/models/interfaces/ResponseLog";
import { ResponseLogModel } from "@/models/ResponseLogModel";
import { ResponseLogSearchParamModel } from "@/models/ResponseLogSearchParamModel";

/**
 * @summary タスク情報に関するStoreを提供します.
 */
@Module({ dynamic: true, store, name: "responseLog", namespaced: true })
export class ResponseLogStore extends VuexModule {
    _responseLogs: ResponseLog[] = [];
    private _selectedIds: number[] = [];

    // #region public getters
    /**
     * @summary 対応ログ一覧
     */
    public get responseLogs(): ResponseLog[] {
        return this._responseLogs;
    }

    public get selectedIds(): number[] {
        return this._selectedIds;
    }
    // #endregion

    // #region mutations
    /**
     * @summary 対応ログ一覧をセット.
     * @param responseLogs
     */
    @Mutation
    public setResponseLogs(responseLogs: ResponseLog[]) {
        this._responseLogs = responseLogs;
    }

    /**
     * @summary 選択状態にします
     * @param {number} responseLogId 対応ログID
     */
    @Mutation
    public selectBy(responseLogId: number): void {
        this._selectedIds = [responseLogId];
    }

    /**
     * @summary 選択状態の対応ログを追加します
     * @param {number} responseLogId 対応ログID
     */
    @Mutation
    public addSelectBy(responseLogId: number): void {
        if (this._selectedIds.indexOf(responseLogId) >= 0) {
            return;
        }

        this._selectedIds.push(responseLogId);
    }

    /**
     * @summary 未選択状態にします
     * @param {number} responseLogId 対応ログID
     */
    @Mutation
    public unselectBy(responseLogId: number): void {
        this._selectedIds = this._selectedIds.filter(selectedId => selectedId !== responseLogId);
    }
    // #endregion

    // #region actions
    /**
     * @summary 対応ログ一覧をサーバーから取り込みます.
     */
    @Action
    public async fetchResponseLogs(searchParam: ResponseLogSearchParamModel): Promise<void> {
        try {
            const response = await axios.get<ResponseLog[]>("api/response_log" + searchParam.getQueryString());
            this.setResponseLogs(response.data);
        }
        catch {
            console.log("error");
        }
    }

    /**
     * @summary 対応ログを新規追加します.
     * @param responseLog 新規追加する対応ログ
     * @returns 追加に成功したかどうか
     */
    @Action
    public async addResponseLog(responseLog: ResponseLog): Promise<ResponseLog | undefined> {
        try {
            const response = await axios.post<ResponseLog>("api/response_log", responseLog);
            return response.data;
        }
        catch {
            console.error("対応ログの追加に失敗しました.");
            return undefined;
        }
    }

    /**
     * @summary 対応ログを保存します
     * @param responseLog 保存する対応ログ
     * @return 保存に成功したかどうか
     */
    @Action
    public async saveResponseLog(responseLog: ResponseLog): Promise<ResponseLog | undefined> {
        try {
            const response = await axios.put<ResponseLog>("api/response_log/" + responseLog.responseLogId, responseLog);
            return response.data;
        }
        catch {
            console.error("対応ログの更新に失敗しました.");
            return undefined;
        }
    }

    /**
     * @summary 対応ログを削除します.
     * @param responseLog 削除する対応ログ
     * @returns 削除に成功したかどうか
     */
    @Action
    public async deleteResponseLog(responseLog: ResponseLog): Promise<boolean> {
        try {
            const response = await axios.delete("api/response_log/" + responseLog.responseLogId);
            return true;
        }
        catch {
            console.error("対応ログの削除に失敗しました.");
            return false;
        }
    }
    // #endregion
}

export const ResponseLogStoreModule = getModule(ResponseLogStore);
